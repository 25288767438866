import React from 'react';
import { useState } from 'react';
import Icon from '../Icon';


export default ({ isDisabled }) => {
  const [filename, setFilename] = useState("Arquivo a ser enviado");

  return (
    <fieldset disabled={isDisabled} >
      <div class="field">
        <label class="label">Seu Nome</label>
        <div class="control">
          <input class="input" type="text" placeholder="Nome" />
        </div>
      </div>
      <div class="field">
        <label class="label">Razão do Contato</label>
        <div class="control is-expanded">
          <div class="select is-fullwidth isblc">
            <select>
              <option>Selecione o motivo do seu contato</option>
              <option>Dúvidas</option>
              <option>Sugestões</option>
              <option>Propostas e  Parcerias</option>
              <option>Faça parte do nosso time</option>
            </select>
          </div>
        </div>
      </div>
      <div class="file has-name is-fullwidth pt-3">
        <label class="file-label">
          <input class="file-input" type="file" name="pdf" onChange={e =>
            e.target.files[0] && setFilename(e.target.files[0].name)
          } />
          <span class="has-background-link has-text-white file-cta">
            <Icon size='32' color='#fff' iconName='attachment'></Icon>
            <span class="file-label">
              Arquivo
            </span>
          </span>
          <span class="file-name">
            {filename}
          </span>
        </label>
      </div>
      <div class="field pt-4">
        <div class="control">
          <textarea class="textarea" placeholder="Descrição" rows='8'></textarea>
        </div>
      </div>
      <div class="field">
        <div class="control is-expanded">
          <button class="button is-link is-fullwidth">Enviar</button>
        </div>
      </div>
    </fieldset >
  )
}