import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: -24.494704,
  lng: -47.842933
};

const alfaPosition = {
  lat: -24.494704,
  lng: -47.842933
}

export default () => (
    <LoadScript
      googleMapsApiKey="AIzaSyB1TzdniHOjIZY_Nj7ex9LEVB0bkchA5MI"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
      >
        <Marker
          position={alfaPosition}
        />

      </GoogleMap>
    </LoadScript>
)