import React from 'react';
import Icon from '../Icon';
import { PhoneNumber, EmailAdress } from '../../consts'

export default () => (
  <footer className='footer has-background-primary has-text-white-bis'>
    <div className='content has-text-centered columns'>
      <p className='column'>
        <Icon iconName='phone' size='32' color='white' />
        {PhoneNumber} - {EmailAdress}
      </p>
      <p className='column'>
        Todos os Direitos Reservados - Alfa Excelência Diagnóstica LTDA.
      </p>
    </div>
  </footer>
);
