import React from 'react';
import './style.css';

export default () => (
  <section data-aos='fade' data-aos-duration='1000' id='hero-landing' className='hero is-fullheight-with-navbar'>
    <div id='hero-body-landing' className='hero-body'>
      <div id="hero-column-container" className='columns container'>
        <div data-aos='fade-right' data-aos-duration='1400' id='card1' className='column is-5'>
          <p className='title is-1 has-text-info'>
            ALFA - Excelência Diagnóstica
          </p>
          <p className='subtitle is-4 has-text-dark has-text-weight-semibold'>
            Somos especialistas na arte de cuidar de pessoas.
          </p>
        </div>
        {/* <div data-aos='fade-right' data-aos-duration='1400' id='card2' className='column is-offset-3 is-5'>
          <p className='title has-text-dark'>
            A maior clinica diagnóstica do Vale do Ribeira
          </p>
          <p className='subtitle has-text-dark'>
            Somos
          </p>
        </div> */}
      </div>
    </div>
  </section>
);
