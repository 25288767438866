import React from 'react';
import './style.css';
import Icon from '../../components/Icon';

/* Sessões:
  História
  Missão, Visão, Valores
  Nossa Equipe
  Infraestrutura
*/

export default () => (
  <section id='about-hero' className='hero is-fullheight has-background-white'>
    <div className='mb-4'>
      <p id='landing-title' className='title has-text-centered has-text-info'>Sobre Nós</p>
    </div>
    <div className='container mb-6'>
      <div className='tile is-ancestor ml-0 mr-0 mt-0'>
        <div className='tile is-parent is-vertical'>
          <div className='tile is-parent' data-aos='fade-up' >
            <article id='about-img' className="tile is-child box is-primary" />
          </div>
          <div className='tile is-parent pt-6' data-aos='fade-up' data-aos-duration='800'>
            <article className="tile is-child box is-primary">
              <p className='mt-2 title has-text-centered'>Excelência em Qualidade</p>
              <ul className='has-text-centered'>
                <li>Experiência e qualificação técnica de nossa equipe. </li>
                <li>Investimentos em infraestrutura e aquisição dos mais modernos equipamentos laboratoriais da região.</li>
                <li>Programas inovadores de gestão e controle dos processos de trabalho junto aos recursos humanos. </li>
                <li>Parceria e colaboração entre profissionais visando o cuidado integral e a promoção e prevenção em Saúde. </li>
              </ul>
            </article>
          </div>
          <div className='tile is-parent pt-6' data-aos='fade-up' >
            <article className="tile is-child box is-primary">
              <p className='mt-2 title has-text-centered'>Nossa História</p>
              <p className='has-text-centered'>
                Temos mais de 20 anos de experiência prestando serviços de diagnósticos laboratoriais
                de excelência tanto em parcerias públicas quanto privadas. Atualmente através da fusão
                dos maiores laboratórios da região formamos o Grupo ALFA Excelência Diagnóstica, empresa
                que conta atualmente com mais de 100 colaboradores, com sua matriz na cidade de Registro-SP e
                laboratórios e Unidades de coleta em diversos municípios como
                Pariquera-Açu, Miracatu. Cajati, Juquiá, Eldorado, Iguape, Ilha Comprida e Cananéia no litoral sul de São Paulo,
                além de unidades em hospitais e serviços públicos da região.
              </p>
            </article>
          </div>
          <div className='tile is-parent pt-6' data-aos='fade-up' data-aos-delay='500'>
            <div className=''>
              <p className='title has-text-centered'>Nossos Ideais</p>
              <div className='tile'>
                <div className='tile is-parent' data-aos='fade-in' data-aos-duration='800' data-aos-delay='300' >
                  <article className='tile is-child box is-info'>
                    <p className='title is-4 has-text-centered'>
                      Missão
                    </p>
                    <p className='has-text-centered is-large subtitle'>
                      <Icon iconName='medCross' size='120' color='#1b9fbb' />
                    </p>
                    <p className='has-text-centered'>
                      Oferecer serviços de Saúde de maneira integral e humanizada, com excelência cuidado e respeito ao cliente.
                    </p>
                  </article>
                </div>
                <div className='tile is-parent' data-aos='fade-in' data-aos-duration='800' data-aos-delay='400'>
                  <article className='tile is-child box is-info'>
                    <p className='title is-4 has-text-centered'>Visão</p>
                    <p className='has-text-centered is-large subtitle'>
                      <Icon iconName='compass' size='120' color='#1b9fbb' />
                    </p>
                    <p className='has-text-centered'>
                      Tornar-se referência em Medicina Diagnóstica no Vale do Ribeira, através de um espaço inovador e de colaboração entre os profissionais parceiros.
                    </p>
                  </article>
                </div>
                <div className='tile is-parent' data-aos='fade-in' data-aos-duration='800' data-aos-delay='500'>
                  <article className='tile is-child box is-info'>
                    <p className='title is-4 has-text-centered'>Valores</p>
                    <h1 className='has-text-centered is-large subtitle' style={{ fontSize: 12 }}>
                      <Icon iconName='diamond' size='120' color='#1b9fbb' />
                    </h1>
                    <ul className='has-text-centered'>
                      <li>Humanização</li>
                      <li>Excelência</li>
                      <li>Colaboração</li>
                      <li>Ética</li>
                      <li>Inovação</li>
                    </ul>
                  </article>
                </div>
              </div>
            </div>
          </div>
          <div className='tile is-parent pt-6' data-aos='fade-up' data-aos-duration='800'>
            <article className="tile is-child box is-primary">
              <p className='mt-2 title has-text-centered'>Infraestrutura</p>
              <p className='has-text-centered'>
                Nós somos atualmente o maior centro de diagnósticos laboratoriais do Vale do Ribeira,
                oferecendo a você o que há de melhor e mais moderno em Medicina Diagnóstica e Saúde Preventiva,
                proporcionando uma experiência única na arte de cuidar.
                Nossos clientes são atendidos de maneira humanizada e acolhedora,
                além de outros benefícios como agilidade, precisão, confiabilidade, tecnologia inovadora na área laboratorial.
              </p>
            </article>
          </div>
          {/* <div className='tile'>
            Pro futuro: Usar countup pra fazer uns gráficos bonitinhos
           <div className='tile is-parent is-8' data-aos='fade-up' data-aos-duration='800' data-aos-delay='200'>
              <article className='tile is-child box is-info'>
                Dados - N Funcionarios, Unidades, Qtd Exames realizados, etc.
                Usar react-countup
              </article>
            </div>
            <div className='tile is-parent' data-aos='fade-up' data-aos-duration='800' data-aos-delay='500'>
              <article className='tile is-child box is-info'>
                Fotos
              </article>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    <p className='title has-text-centered' data-aos='fade-in'>Tudo isso para melhor atendê-lo</p>
    <p className='subtitle has-text-centered pb-6' data-aos='fade-in'>Seja bem vindo</p>

  </section>
);
