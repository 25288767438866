import React from 'react';
import './style.css';


import Icon from '../../components/Icon';
import ContactForm from '../../components/ContactForm';
import { PhoneNumber, EmailAdress } from '../../consts';
/* Sessões
  Enviar um email sobre qualquer assunto
  Pode incluiar algum PDF
*/
export default () => (
  <section className='hero is-fullheight'>
    <div className='mb-4'>
      <p id='landing-title' className='title has-text-centered has-text-info'>Contato</p>
    </div>
    <div className='container' data-aos='fade-in'>
      <div className='columns mb-0'>
        <article className='content box mb-0 contact-half'>
          <p className='title is-4 has-text-centered has-text-info'>Nossos meios de comunicação</p>
          <ul className='pt-5'>
            <li><Icon iconName='phone' size='24' color='#1b9fbb' />{PhoneNumber}</li>
            {/*  Possivelmente adiconar link para o whatsapp */}
            {/* <li><Icon iconName='cell' size='24' color='#1b9fbb' />(13) 99767-7775 </li> */}
            <li><Icon iconName='mail' size='24' color='#1b9fbb' /> {EmailAdress}</li>
          </ul>
          <p className='title has-text-centered is-5 pt-6'>Horario de Atendimento</p>
          <p className='subtitle has-text-centered is-6'>De Segunda a Sexta das 07:00 as 17:30</p>
          <p className='title has-text-centered is-5 pt-6'>Estamos sempre a disposição</p>
          <p className='subtitle has-text-centered is-6'>Ou se preferir, envie uma mensagem diretamento do nosso site utilizando o formulário a seguir.</p>
        </article>
        <article className='box contact-half'>
          <ContactForm isDisabled={true} />
        </article>
      </div>
    </div>
  </section>
);
