import React from 'react';
import logo from '../../imgs/logo.png';
import './style.css';
import './toggleScript.js';

export default () => (
  <>
    <nav className='navbar is-fixed-top' role='navigation' aria-label='main navigation'>
      <div className='navbar-brand'>
        <a className='navbar-item' href='/'>
          <img className='nav-logo' src={logo} alt='Logo Santa Dulce' />
        </a>
        {/* eslint-disable-next-line */}
        <a role='button' className='navbar-burger burger is-large' aria-label='menu' aria-expanded='false' data-target='navbarBasic'>
          <span aria-hidden='true' />
          <span aria-hidden='true' />
          <span aria-hidden='true' />
        </a>
      </div>

      <div id='navbarBasic' className='navbar-menu'>
        <div className='navbar-end is-size-4 is-size-5-mobile'>
          <a className='navbar-item' href='/about'>
            Sobre nós
          </a>
          <a className='navbar-item' href='/locations'>
            Localização
          </a>
          <a className='navbar-item' href='/insurance'>
            Convênios
          </a>
          <a className='navbar-item' href='/contact'>
            Contato
          </a>
          <a href='https://alfalaudos.avantix.com.br/alfa/' id='exam-results' className='navbar-item has-background-primary has-text-dark'>
            Resultados
          </a>
        </div>
      </div>
    </nav>
    <nav id='fake-navbar' className='navbar' role='navigation' aria-label='main navigation'>
      <div className='navbar-brand'>
        <a className='navbar-item' href='/'>
          <img className='nav-logo' src={logo} alt='Logo Santa Dulce' />
        </a>
        {/* eslint-disable-next-line */}
        <a role='button' className='navbar-burger burger is-large' aria-label='menu' aria-expanded='false' data-target='navbarBasic'>
          <span aria-hidden='true' />
          <span aria-hidden='true' />
          <span aria-hidden='true' />
        </a>
      </div>


    </nav>
  </>
);
