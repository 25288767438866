import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './index.css';

// Paginas para o Roteador
import Landing from './pages/Landing';
import About from './pages/About';
import Locations from './pages/Locations';
import Contact from './pages/Contact';
import Insurance from './pages/Insurance';

// Footer e Navbar presentes em todas as paginas
import Footer from './components/Footer';
import Navbar from './components/Navbar';

// Animações
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
  once: true,
  duration: 800
});

// Render
ReactDOM.render(
  <React.StrictMode>
    <Navbar />
    <Router>
      <Switch>
        <Route path='/about' component={About} />
        <Route path='/contact' component={Contact} />
        <Route path='/locations' component={Locations} />
        <Route path='/insurance' component={Insurance} />
        <Route exact path='/' component={Landing} />
      </Switch>
    </Router>
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);
