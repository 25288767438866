import React from 'react';
import './index.css';

export default ({img, alt}) => (
  <div class="tile is-parent">
    <article class="tile is-child box is-insurance-tile">
      <img src={img} alt={alt}/>
    </article>
  </div>
)
