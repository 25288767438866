import React from 'react'




export default ({ children, delay }) => {
  return (
    <div>
      <div class="tile is-ancestor ml-0 mr-0 mt-0" data-aos='fade-up' data-aos-delay={delay}> 
        { children }
      </div>
    </div>
  )
}