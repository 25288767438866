import React from 'react';
import './style.css';


import Icon from '../../components/Icon'
import Map from '../../components/GoogleMap'
import { PhoneNumber } from '../../consts'

/* Sessões
  Todas as unidades
  Mais informações sobre cada localização: Telefones, Mapa, etc
  Foto de cada localidade
  "Base de dados" em JSON que o React vai usar pra pegar as informações"
*/
export default () => (
  <section className='hero is-fullheight'>
    <div className='pb-6'>
      <div id='location-img' data-aos='fade-in'></div>
      {/* <p id='location-title' className='title has-text-centered has-text-info pt-4'>Localização</p> */}
      <div className='container columns pt-6'>
        <div className='box column is-8 mb-0 pl-6' data-aos='fade-in' data-aos-delay='500'>
          <p className='title has-text-info'>Localização</p>
          <div class='content is-medium'>
            <p>Estamos localizados na Rua Tamekishi Takano, 445 - Centro - Registro</p>
            {/* <p>Contamos com uma estrutura xyz para melhor atendê-lo</p> */}
            <p><Icon iconName='phone' size='32' color='#1b9fbb' /> {PhoneNumber}</p>
            <a href='https://www.google.com/maps/place/Alfa+-+Cl%C3%ADnica+e+Medicina+Diagn%C3%B3stica/@-24.4947132,-47.8451238,17z/data=!3m1!4b1!4m8!1m2!2m1!1sclinica+alfa!3m4!1s0x94c5331ac3cd4fe9:0xc8a5137fe1ce2d20!8m2!3d-24.4947181!4d-47.8429351'>
              Navegue para nossa localização
            </a>
          </div>
        </div>
        <div className='column map-container' data-aos='fade-in' data-aos-delay='500'>
          <Map />
        </div>
      </div>
    </div>
  </section>
);
