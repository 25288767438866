import React from 'react';

import TileRows from '../../components/TileRows';
import Tiles from '../../components/Tiles';

// Exemplos
// import brad from '../../imgs/convenios/brad.png'
// import prev from '../../imgs/convenios/prev.jpg'
// import umed from '../../imgs/convenios/unimed.jpg'

import amil from '../../imgs/insurances/amil.jpg'
import angelus from '../../imgs/insurances/angelus.jpg'
import apas from '../../imgs/insurances/apas.jpg'
import bradesco from '../../imgs/insurances/bradesco-saude.jpg'
import cabesp from '../../imgs/insurances/cabesp.jpg'
import caixa from '../../imgs/insurances/caixa-saude.jpg'
import cassi from '../../imgs/insurances/cassi.jpg'
import economus from '../../imgs/insurances/economus.jpg'
import mediservice from '../../imgs/insurances/mediservice.jpg'
import nipomed from '../../imgs/insurances/nipomed.jpg'
import postal from '../../imgs/insurances/postal-saude.jpg'
import prudential from '../../imgs/insurances/prudential.jpg'
import sabesp from '../../imgs/insurances/sabesp.jpg'
import sulamerica from '../../imgs/insurances/sulamerica.jpg'
import vivest from '../../imgs/insurances/vivest.jpg'


/* Sessões
  Enviar um email sobre qualquer assunto
  Bem parecido com o de enviar o currículo, porém sem a opção de enviar currículo (obvio)
*/
export default () => (
  <section className='hero is-fullheight'>
    <div className='pb-6 container'>
      <p id='landing-title' className='title has-text-centered has-text-info'>Convênios Atendidos</p>
      <TileRows delay={100}>
        <Tiles img={amil} alt="Amil" />
        <Tiles img={bradesco} alt="Bradesco" />
        <Tiles img={sulamerica} alt="Sulamérica" />
      </TileRows>
      <TileRows delay={200}>
        <Tiles img={angelus} alt="Angelus" />
        <Tiles img={apas} alt="Apas" />
        <Tiles img={cabesp} alt="Cabesp" />
      </TileRows>
      <TileRows delay={300}>
        <Tiles img={caixa} alt="Caixa" />
        <Tiles img={cassi} alt="Cassi" />
        <Tiles img={economus} alt="Economus" />
      </TileRows>
      <TileRows delay={400}>
        <Tiles img={vivest} alt="Vivest" />
        <Tiles img={mediservice} alt="Mediservice" />
        <Tiles img={nipomed} alt="Nipomed" />
      </TileRows>
      <TileRows delay={500}>
        <Tiles img={postal} alt="Postal" />
        <Tiles img={prudential} alt="Prudential" />
        <Tiles img={sabesp} alt="Sabesp" />
      </TileRows>
    </div>
    <p className='title has-text-centered' data-aos='fade-in'>
      Tem alguma dúvida?
    </p>
    <p className='subtitle has-text-centered pb-6' data-aos='fade-in'>
      <a href='/contact'>
        Aqui estão nossos meios de comunicação
      </a>
    </p>
  </section>
);
